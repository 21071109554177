import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Modal from 'react-modal';
import * as Yup from 'yup';
import CodiceFiscale from 'codice-fiscale-js';
import ErrorFocus from '../../../ErrorFocus';
import {DEFAUL_ERROR_MESSAGE, OBJECTS_PATH} from '../../../App.js';
import i18n from '../../../translations/i18n.js';
import {DEFAULT_LANGUAGE, parseLanguage, SUPPORTED_LANG} from "../../../translations/i18n";
import {Helmet} from "react-helmet";
import {Redirect} from "react-router-dom";
import {ERROR_CODES} from "../../../App";

import ReactDOMServer from 'react-dom/server';
import LocalizationService from "../../../translations/configLangContext";

const DEFAULT_ERR_KEY = "econsent.form.campoObbligatorio";
const DEFAULT_AT_LEAST_ONE_CH = "professional.errorMessages.atLeastOneChannel";
const STEP_INFO = {
    USER_DATA: "user_data",
    DEEP_SEARCH: 'deep_search',
    INTL_FLOW: 'intl_flow',
    CONSENT_PROMPT: 'consent_prompt'
}
const CONFIG_ARRAY = {
    firstName: { step: [STEP_INFO.USER_DATA, STEP_INFO.DEEP_SEARCH], errKey: DEFAULT_ERR_KEY, required: true },
    lastName: { step: [STEP_INFO.USER_DATA, STEP_INFO.DEEP_SEARCH], errKey: DEFAULT_ERR_KEY, required: true },
    professionalType: { step: [STEP_INFO.USER_DATA, STEP_INFO.DEEP_SEARCH], errKey: DEFAULT_ERR_KEY, required: true },
    step: { step: [STEP_INFO.USER_DATA, STEP_INFO.DEEP_SEARCH], errKey: DEFAULT_ERR_KEY, required: true },
    email: { step: [STEP_INFO.USER_DATA, STEP_INFO.DEEP_SEARCH], errKey: "professional.notValidEmail", required: true, customCheck: true },
    emailrepeat: { step: [STEP_INFO.USER_DATA, STEP_INFO.DEEP_SEARCH], required: true, customCheck: true },
    codiceFiscale: { step: [STEP_INFO.USER_DATA, STEP_INFO.DEEP_SEARCH], errKey: DEFAULT_ERR_KEY, customCheck: true, required: true },
    citta: { step: [STEP_INFO.DEEP_SEARCH], errKey: DEFAULT_ERR_KEY, required: true },
    phone: { step: [STEP_INFO.DEEP_SEARCH], errKey: DEFAULT_ERR_KEY, required: true },
    luogoLavoro: { step: [STEP_INFO.DEEP_SEARCH], errKey: DEFAULT_ERR_KEY, required: true },
    reparto: { step: [STEP_INFO.DEEP_SEARCH], errKey: DEFAULT_ERR_KEY, required: true },
    indirizzo: { step: [STEP_INFO.DEEP_SEARCH], errKey: DEFAULT_ERR_KEY, required: true },
    specializzazione: { step: [STEP_INFO.DEEP_SEARCH], errKey: DEFAULT_ERR_KEY, required: true, useFieldLevelValidation: true }, // useFieldLevelValidation: true perchè è una select required e viene validata con validateSpec(value)
    annoLaurea: { step: [STEP_INFO.DEEP_SEARCH], errKey: DEFAULT_ERR_KEY, customCheck: true, required: false, regexRequired: true},
    cap: { step: [STEP_INFO.DEEP_SEARCH], customCheck: true, required: true },
    pharmacyName: { step: [STEP_INFO.DEEP_SEARCH], errKey: DEFAULT_ERR_KEY, required: true },
    terms_intl: { step: [STEP_INFO.INTL_FLOW], required: true },
    nazione: { required: false },
    source: { required: false },
    culture: { required: false },
    national_id_typeCode: { required: false },
    codBase: { required: false },
    domainInfo: { required: false },
    referrer: { required: false },
    target_url: { required: false },
    change_pass_token: { required: false },
    aid_code: { required: false }
}

const DYNAMIC_DISPLAY_CONFIG_ARRAY = {
    firstName: { step: []},
    lastName: { step: []},
    professionalType: { step: []},
    step: { step: []},
    email: { step: []},
    emailrepeat: { step: []},
    codiceFiscale:{ step: []},
    citta:{ step: []},
    phone: { step: []},
    luogoLavoro:{ step: []},
    reparto:{ step: []},
    indirizzo: { step: []},
    specializzazione:{ step: []},
    annoLaurea:{ step: []},
    cap: { step: []},
    terms_intl:{ step: []},
    provinces: { step: []},
    consents: { step: []},
    pharmacyName: { step: []}
}



/**
 * Instructions to make the call to get specialization list:
 * endpoint: ONEKEY_URL_SEARCH (prod.yaml and qa.yaml)
 * body: { "isoCod2" : "it", "isoLanguage" : "it", "fields": [{ "method": "EXACT", "name": "list.externalId", "values": ["SP"] }] }
 * change isoCod2 and isoLanguage
 */

export default class SignupProfessional extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            step: STEP_INFO.USER_DATA,
            provinces: [],
            specialization: [],
            consentVisitCheck: true,
            consentsFetched: {},
            consentsInfo: {},
            deepSearchRequired: false,
            consentPromptRequired: false,
            isModalOpen: false,
            isErrorModalOpen: false,
            isSubmitClicked: false,
            isFetchProcessing: false,
            isEditMode: false,
            currentUser: {},
            event_id: null
        };
        this.source = null;
        this.polishC4Check = this.polishC4Check.bind(this);
        this.submitBtnManager = this.submitBtnManager.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.channelsValuesCheck = this.channelsValuesCheck.bind(this);
        this.channelCheckboxLabelManager = this.channelCheckboxLabelManager.bind(this);
        if (props.location.state) this.target = props.location.state.target;  //set redirect target if it has been pushed from landing
        this.parsedLanguage = null;
        this.landingUrl = "/";
        if(this.props.successLanding) this.landingUrl = this.props.successLanding;
        if (props.location.state) this.target = props.location.state.target;  //set redirect target if it has been pushed from landing
        this.intlflag = false;
        this.errorText = null;
        this.subLanPlaceHolder = null;
        this.subLangOptions = [];
        this.channelsValues = {};
        this.channelsPreflagChanged = {};
        this.countryForced = null;
        this.intl_flow_type = null;
        this.individualEid = null;

        this.specializzazioneSelected = null;
        this.provinciaSelected = null;
        this.translateCompleted = false;
    }

    fetchUserByIEID(eid) {
        let values = { reqType: "signupCheckByEid", individualEid: eid, domainUrl: window.location.origin };
        let data = JSON.stringify(values, null, 2);
        fetch(this.props.dbSelectService, {
            credentials: 'same-origin',
            method: 'POST',
            body: data,
            headers: new Headers({'Content-Type': 'application/json'})
        }).then(response => {
            if(!response.ok) {
                console.error(`Generic error occurred while fetching user by EID, continuing with 'Standard Signup Flow'`);
                return;
            }
            return response.json();
        }).then(data => {
            if(!data || !data.res) {
                console.error(`Generic error occurred while fetching user by EID, continuing with 'Standard Signup Flow'`);
                return;
            }
            if(data.res.isCrm) this.setState({isEditMode: true, currentUser: data.res.userObj});
            else if(data.res.isAiD && data.res.userObj.userid) {
                let loginRedirectUrlComplete = (data.res.loginRedirectUrl.includes('?') ? (data.res.loginRedirectUrl + '&') : (data.res.loginRedirectUrl + '?'));
                loginRedirectUrlComplete += `userid=${data.res.userObj.userid}`;
                window.location.href = loginRedirectUrlComplete;
            }
            else if(data.res.isMultiple) {
                let errorCodeIntro = !i18n.t('errorCodeIntro').includes('errorCodeIntro') ? i18n.t('errorCodeIntro') : "Error Code";
                let message = i18n.t("professional.errorMessages.genericError");
                message += " (" + errorCodeIntro + ": " +  ERROR_CODES.REGISTRATION.EID_BASED_SIGNUP_MULTIPLE_FOUND + ")";
                this.errorText = message;
                this.setState({isErrorModalOpen: true});
                document.getElementsByTagName("html")[0].classList.add("is-aid-dialog");
            }
        }).catch( async error => {
            await this.noInternetConnectionCheck(error, this.props.aidApiService);
        })
    }

    profTypeKeysHandler(wordKey) {
        if(this.props.profType) return wordKey + "_" + this.props.profType;
        return wordKey;
    }

    consentPreFlaggedToggle(e, consentName) {
        this[consentName] = !this[consentName];
        e.stopPropagation();
    }

    async componentDidMount() {

        const langContext = LocalizationService.getInstance();
        await langContext.saveAndSynch(this.props.dalService, this.props.profType, this.parsedLanguage.CULTURE_CODE, "signup_professional");

        this.individualEid = new URL(window.location.href).searchParams.get('okeid');
        if (this.individualEid) this.fetchUserByIEID(this.individualEid);
        this.consentsFetcher(this.parsedLanguage.CULTURE_CODE, this.props.profType);      // get consents for parsed country
        this.subLanguageMenuRender();
        window.setNiceSelect();
        document.querySelectorAll("#sublanguages + .nice-select li.option").forEach(el => {
            el.addEventListener("click", event => {
                this.subLanguageEventHandler(event.target.getAttribute("data-value"));
            });
        });
        document.querySelectorAll("select + .specializzazione").forEach(el => {
            el.addEventListener("click", event => {
                // document.getElementsByName("specializzazione")[0].value = event.target.getAttribute(
                this.specializzazioneSelected = event.target.getAttribute("data-value");
            });
        });
        document.querySelectorAll("select + .provincia").forEach(el => {
            el.addEventListener("click", event => {
                // document.getElementsByName("provincia")[0].value = event.target.getAttribute("data-value");
                this.provinciaSelected = event.target.getAttribute("data-value");
            });
        });
        this.setState({ translateCompleted: true});
    }

    componentDidUpdate(prevProps, prevState) {
        window.setNiceSelect();
        document.querySelectorAll("#sublanguages + .nice-select li.option").forEach(el => {
            el.addEventListener("click", event => {
                this.subLanguageEventHandler(event.target.getAttribute("data-value"))
            })
        });
        document.querySelectorAll("select + .specializzazione").forEach(el => {
            el.addEventListener("click", event => {
                // document.getElementsByName("specializzazione")[0].value = event.target.getAttribute("data-value");
                this.specializzazioneSelected = event.target.getAttribute("data-value");
            });
        });
        document.querySelectorAll("select + .provincia").forEach(el => {
            el.addEventListener("click", event => {
                // document.getElementsByName("provincia")[0].value = event.target.getAttribute("data-value");
                this.provinciaSelected = event.target.getAttribute("data-value");
            });
        });
        if(document.getElementById('emailrepeat')) document.getElementById('emailrepeat').onpaste = e => e.preventDefault();    // if "email repeat" available, disable paste
    }

    consentsFetcher(culture, profType) {
        // request consents by calling "dbSelect.js" lambda. We need the most recent policy of a specified country
        if(!culture) return;
        let values = {reqType: "privacyPolicy", privacyQueryFilter: { getLastPolicy: true, culture: culture, professional_type: profType}}
        let data = JSON.stringify(values, null, 2);
        fetch(this.props.dbSelectService, {
            credentials: 'same-origin', // 'include', default: 'omit'
            method: 'POST',             // 'GET', 'PUT', 'DELETE', etc.
            body: data, // Use correct payload (matching 'Content-Type')
            headers: new Headers({'Content-Type': 'application/json'})
        }).then(response => {
            if(!response.ok) {
                if(response.status === 422) console.log("WARNING: NO CONSENTS FETCHED");
                else console.log("generic Error");
            }
            else return response.json();
        }).then(data => {
            if(data) {
                this.setState({ consentsFetched: data.answer.filteredConsents });
                this.setState({ consentsInfo: data.answer.consentsInfo });
            }
            else this.setState({ consentsFetched: {error: true} });
        }).catch( async error => {
            await this.noInternetConnectionCheck(error, this.props.dbSelectService);
        })
    }

    async noInternetConnectionCheck(error, url) {
        console.error(`ERROR: An error occurred while submitting: ${error}`);
        await fetch(this.props.dbSelectService, {
            // checking if connection is active by pinging 'this.props.dbSelectService' url
            credentials: 'same-origin',
            method: 'POST',
            body: JSON.stringify({reqType: "internetConnectionCheck"}),
            headers: new Headers({'Content-Type': 'application/json'})
        }).catch(pingError => {
            // Ping to 'this.props.dbSelectService' url went wrong, no internet connection detected
            console.error(`ERROR: Ping to URL ${this.props.dbSelectService} failed, no internet connection. Error message: ${pingError}`);
            this.errorText = !i18n.t('professional.errorMessages.no_internet_connection').includes('professional.errorMessages.no_internet_connection') ?
                i18n.t("professional.errorMessages.no_internet_connection") : DEFAUL_ERROR_MESSAGE.NO_INTERNET_CONNECTION;
            this.errorText += " (Error Code: " + ERROR_CODES.REGISTRATION.NO_INTERNET_CONNECTION + ")";
        });
        if (!this.errorText) {
            // Ping to 'this.props.dbSelectService' url went fine, maybe the backend crashed
            console.info(`Ping to ${this.props.dbSelectService} worked, maybe a crash occurred in ${url} function`);
            this.errorText = !i18n.t('professional.errorMessages.genericError').includes('professional.errorMessages.genericError') ?
                i18n.t("professional.errorMessages.genericError") : DEFAUL_ERROR_MESSAGE.GENERIC_ERROR;
            this.errorText += " (Error Code: " + ERROR_CODES.REGISTRATION.BACKEND_CRASH + ")";
        }
        this.setState({isErrorModalOpen: true});
        document.getElementsByTagName("html")[0].classList.add("is-aid-dialog");
    }

    validationJsonBuilder(step, country) {

        const pathUrl = this.props.domainInfo;
        for (let field in DYNAMIC_DISPLAY_CONFIG_ARRAY) {
            let targetKey = field + '_step';
            for (let key in pathUrl) {
                if (key === targetKey) {
                    let value = pathUrl[key];
                    // console.log("valore: "+value)
                    if (value) {
                        DYNAMIC_DISPLAY_CONFIG_ARRAY[field].step = [value];
                        // console.log("display_config: "+DYNAMIC_DISPLAY_CONFIG_ARRAY[field].step);
                        if (field in CONFIG_ARRAY && field in DYNAMIC_DISPLAY_CONFIG_ARRAY) {
                            if (!CONFIG_ARRAY[field].step.includes(value)) {
                                CONFIG_ARRAY[field].step.push(value);
                            }
                        }

                    }
                }
            }
        }


        let zipRegex = this.zipHandler(country);
        let validationJson = {};
        for(let elemKey in CONFIG_ARRAY) {
            let elem = CONFIG_ARRAY[elemKey];
            if (elem.hasOwnProperty("useFieldLevelValidation") && elem.useFieldLevelValidation === true) // the field has a field-level validation so w it's skipped
                continue;

            if (DYNAMIC_DISPLAY_CONFIG_ARRAY[elemKey] && DYNAMIC_DISPLAY_CONFIG_ARRAY[elemKey].step.length > 0 && !DYNAMIC_DISPLAY_CONFIG_ARRAY[elemKey].step.includes(step)) {
                // console.log(elemKey + " non required");
                continue;
            }

            if (((elem.hasOwnProperty("required") && elem.required === true) || (elem.hasOwnProperty("regexRequired") && elem.regexRequired === true)) && elem.hasOwnProperty("step") && (elem.step.includes(step))) {
                // console.log("Applying required to: "+elemKey+" for step:"+step);
                let i18nKey = (elem.hasOwnProperty("errKey") && elem.errKey.length > 0) ? elem.errKey : DEFAULT_ERR_KEY;
                let yupValue = Yup.string().nullable().required(i18n.t(i18nKey));
                if(this.props.domainInfo.hasOwnProperty(elemKey + "_required")) {
                    // in Path_Url_CL there is a column to describe required of this elem, use that value
                    if(this.props.domainInfo[elemKey + "_required"] === 1) yupValue = Yup.string().nullable().required(i18n.t(i18nKey));
                    else yupValue = Yup.string().nullable();
                }
                if(this.state.isEditMode && this.props.domainInfo.hasOwnProperty(elemKey + "_edit_required")) {
                    // in Path_Url_CL there is a column to describe required of this elem, use that value
                    if(this.props.domainInfo[elemKey + "_edit_required"] === 1) yupValue = Yup.string().nullable().required(i18n.t(i18nKey));
                    else yupValue = Yup.string().nullable();
                }
                if(elem.hasOwnProperty("customCheck") && elem.customCheck) {
                    switch(elemKey) {
                        case "codiceFiscale": {
                            yupValue = this.nationalIdCheck();
                            break;
                        }
                        case "cap": {
                            yupValue = Yup.string().nullable().matches(zipRegex, i18n.t('professional.capError')).required(i18n.t(i18nKey));
                            break;
                        }
                        case "annoLaurea": {
                            yupValue = Yup.string().nullable().matches(/^\d{4}$/,i18n.t('professional.mustBeNumber'));
                            break;
                        }
                        case "email": {
                            yupValue = Yup.string().nullable()
                                .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, i18n.t('professional.notValidEmail')) // eslint-disable-line
                                .required(i18n.t('professional.notValidEmail'));
                            break;
                        }
                        case "emailrepeat": {
                            if(this.props.domainInfo.emailrepeat_required) yupValue = Yup.string().nullable().oneOf([Yup.ref('email'), null], i18n.t("professional.emailRepeatNoMatch")).required(i18n.t(i18nKey));
                            else yupValue = Yup.string().nullable().oneOf([Yup.ref('email'), null], i18n.t("professional.emailRepeatNoMatch"))
                            break;
                        }
                        default: {
                            break;
                        }
                    }
                }
                validationJson[elemKey] = yupValue;
            }
        }
        // console.log(this.state.consentsFetched);
        for(let consentKey in this.state.consentsFetched) {
            let consentObj = this.state.consentsFetched[consentKey];
            let channelsKeys;
            if (consentObj.stepShow === this.state.step) {
                if (consentObj.hasOwnProperty("required") && consentObj.required) {
                    let errKey;
                    if (channelsKeys && channelsKeys.length > 0) {
                        // if consent has channels and it's required, it means that at least on channel must be true, thus we change error message
                        errKey = DEFAULT_AT_LEAST_ONE_CH;
                    } else {
                        errKey = DEFAULT_ERR_KEY;
                    }
                    if (consentObj.customErrKey && consentObj.customErrKey.length > 0) errKey = consentObj.customErrKey;     // if custom message from DB set it
                    validationJson[consentKey] = Yup.boolean().oneOf([true], i18n.t(errKey));
                }
            }
        }
        return validationJson;
    }

    // field-level validation for 'specializzazione'
    // this because it's a select field that uses jquery-nice-select and that needs validation
    // note that the step check is missing because it isn't needed in field-level validation
    validateSpec(value) {
        let error;
        let elem = CONFIG_ARRAY['specializzazione'];
        let i18nKey = (elem.hasOwnProperty("errKey") && elem.errKey.length > 0) ? elem.errKey : DEFAULT_ERR_KEY;
        let htmlOption = document.querySelector("select[name='specializzazione'] + .nice-select li.option.selected");
        let val = htmlOption.getAttribute("data-value");
        if (elem.hasOwnProperty("required") && elem.required === true
            && (!val || val.length === 0)) {
            error = i18n.t(i18nKey);
        }
        return error;
    }

    initValuesJsonBuilder() {
        let initValuesObj = {};
        for(let elemKey in CONFIG_ARRAY) {
            if(!elemKey.hasOwnProperty("doNotInit") || !elemKey.doNotInit) {
                switch (elemKey) {
                    case "nazione": {
                        if(this.props.subLanguagesInfo) {
                            for(let subLan of this.props.subLanguagesInfo) {
                                if(subLan.isDefaultSubLan) initValuesObj[elemKey] = subLan.country;
                                break;
                            }
                        }
                        // default value has not "else" statement to ensure that even if there are sub-languages but no default sub-lan is set, DB value will be persisted with Domain_URL_CL value
                        if(!initValuesObj[elemKey]) initValuesObj[elemKey] = this.parsedLanguage.NATION_CODE;
                        break;
                    }
                    case "professionalType": initValuesObj[elemKey] = (this.props.profType ? this.props.profType : 'M'); break;
                    case "source": initValuesObj[elemKey] = this.source; break;
                    case "culture": initValuesObj[elemKey] = this.parsedLanguage.CULTURE_CODE; break;
                    case "step": initValuesObj[elemKey] = STEP_INFO.USER_DATA; break;
                    case "national_id_typeCode": initValuesObj[elemKey] = this.props.national_id_typeCode; break;
                    case "codBase": initValuesObj[elemKey] = this.props.codBase; break;
                    case "domainInfo": initValuesObj[elemKey] = this.props.domainInfo; break;
                    case "referrer": initValuesObj[elemKey] = this.props.referrer; break;
                    case "target_url": initValuesObj[elemKey] = this.target; break;
                    default: {
                        if(CONFIG_ARRAY[elemKey].hasOwnProperty("defaultValue")) initValuesObj[elemKey] = CONFIG_ARRAY[elemKey].defaultValue;
                        else initValuesObj[elemKey] = this.state.currentUser[elemKey];
                    }
                }
            }
        }
        for(let consentKey in this.state.consentsFetched) {
            let consentObj = this.state.consentsFetched[consentKey];
            this[consentKey] = true;
            let channelsKeys;
            if(consentObj.hasOwnProperty("channels") && consentObj.channels && (channelsKeys = Object.keys(consentObj.channels)).length > 0) {
                // channels found for this consent
                for(let chKey of channelsKeys) {
                    // for each channel for that consent, init value inside 'initValuesObj' and check if preflag
                    const chName = this.channelPpuNameBuilder(consentKey, chKey);
                    initValuesObj[chName] = consentObj.channels[chKey].hasOwnProperty("preflag") ? !!consentObj.channels[chKey].preflag : false;
                    this[chName] = true;
                }
            }
            initValuesObj[consentKey] = this.consentPreFlaggedCheck(channelsKeys, consentObj);
        }
        return initValuesObj;
    }

    channelPpuNameBuilder(consentKey, channelName) {
        return consentKey + "_ch_" + channelName;
    }

    channelsSort(channelsObj, channelsKeys) {
        // sort channels by DB index
        let tmpArr = [], sortedChannelsObj = {};
        for(let chKey of channelsKeys) {
            // create a tmp array where'll push objects which contains 'chKey' and 'index' of each channel
            let tmpObj = {}
            tmpObj.chKey = chKey;
            tmpObj.index = channelsObj[chKey].index;
            tmpArr.push(tmpObj);
        }
        tmpArr.sort(function (a, b) { return a.index - b.index; });   // sort tmp array by index
        for(let arrElem of tmpArr) {
            // once tmp array is sorted, create new channels obj where'll put channels ordered by index
            sortedChannelsObj[arrElem.chKey] = channelsObj[arrElem.chKey];
        }
        return sortedChannelsObj;
    }

    channelCheckboxLabelManager(consentObj, channelName, isPositiveCh) {
        // check if there are some value in positive_label || negative_label cols of selected channel
        if(isPositiveCh) {
            return consentObj.channels && consentObj.channels[channelName] &&
            consentObj.channels[channelName].hasOwnProperty("positiveLabel") && consentObj.channels[channelName].positiveLabel &&
            consentObj.channels[channelName].positiveLabel !== "" ? consentObj.channels[channelName].positiveLabel : i18n.t('professional.positiveChannelsRes');
        }
        return consentObj.channels && consentObj.channels[channelName] &&
        consentObj.channels[channelName].hasOwnProperty("negativeLabel") && consentObj.channels[channelName].negativeLabel &&
        consentObj.channels[channelName].negativeLabel !== "" ? consentObj.channels[channelName].negativeLabel : i18n.t('professional.negativeChannelsRes');
    }

    consentsToRenderBuilder(errors, touched) {
        let elemToRenderArr = [], filteredConsents = [], counter = 0;
        for(let consentKey in this.state.consentsFetched) {
            if(!this.state.consentsFetched[consentKey].manualInsert && this.state.consentsFetched[consentKey].stepShow === this.state.step) {
                let currConsentObj = this.state.consentsFetched[consentKey];
                let preFlagged = currConsentObj.hasOwnProperty("preflag") ? currConsentObj.preflag : false;
                let consIndex = 0;
                if(currConsentObj.hasOwnProperty("index") && currConsentObj["index"]) {
                    // set index read by Privacy_Policy DB table
                    consIndex = currConsentObj["index"];
                } else {
                    // if somehow lambda didn't return index for this consent, set reading order adding X to put index=null consent at the end of the list
                    consIndex += 1000 + counter;
                    counter += 1;
                }
                let channels = {};
                if(currConsentObj.hasOwnProperty("channels") && currConsentObj.channels && Object.keys(currConsentObj.channels).length > 0) {
                    // if there're channels for this consent, add them to consents to render
                    channels = currConsentObj.channels;
                }
                let channelsKeys;
                if((channelsKeys = Object.keys(channels)).length > 0) channels = this.channelsSort(channels, channelsKeys);     // sort channels by index
                filteredConsents.push({index: consIndex, consentName: consentKey, currConsentObj: currConsentObj, preflag: preFlagged, channels: channels});
            }
            // skip consents described in 'CONFIG_ARRAY' that have 'manualInsert' attribute, they'll be insert manually in HTML
        }
        filteredConsents.sort(function (a, b) { return a.index - b.index; });   // sort consents by index
        if (filteredConsents.length === 0) return "";
        for(let consentElem in filteredConsents) {
            // iterate "filteredConsents" to build element to show
            let consentName = filteredConsents[consentElem].consentName;
            let channelsKeys = Object.keys(filteredConsents[consentElem].channels);
            let elemToRender;
            if(channelsKeys.length === 0) { //no channels
                elemToRender =
                    <div className="form-check">
                        <label className="c-label" htmlFor={consentName}>
                            <span className="c-label-text" dangerouslySetInnerHTML={{__html: filteredConsents[consentElem].currConsentObj.prefixtext}} />
                            { this.consentFieldRenderBuilder(consentName, errors, touched, this.consentPreFlaggedCheck(channelsKeys, filteredConsents[consentElem]), null, channelsKeys.length > 0, null, filteredConsents[consentElem], null) }
                            <span className="c-label-text" dangerouslySetInnerHTML={{__html: filteredConsents[consentElem].currConsentObj.text}} />
                            { this.consentErrorRenderBuilder(consentName) }
                            <span className="c-label-text" dangerouslySetInnerHTML={{__html: filteredConsents[consentElem].currConsentObj.subtext}} />
                        </label>
                    </div>
            } else { //channels present
                elemToRender =
                    <fieldset className="u-fieldset u-fieldset-opt">
                        <legend className="c-label">
                            <span className="c-label-text" dangerouslySetInnerHTML={{__html: filteredConsents[consentElem].currConsentObj.prefixtext}} />
                        </legend>
                        { this.consentFieldRenderBuilder(consentName, errors, touched, this.consentPreFlaggedCheck(channelsKeys, filteredConsents[consentElem]), null, channelsKeys.length > 0, null, filteredConsents[consentElem], null) }
                        <legend className="c-label-opts">
                            <span className="c-label-opts__text" dangerouslySetInnerHTML={{__html: filteredConsents[consentElem].currConsentObj.text}} />
                        </legend>
                        { this.consentErrorRenderBuilder(consentName) }

                        <ol className="list list-opt list-opt-inline">
                            {channelsKeys.map(chKey => (this.channelToRenderBuilder(filteredConsents[consentElem].channels[chKey], chKey, consentName, errors, touched, channelsKeys, filteredConsents[consentElem].currConsentObj)))}
                        </ol>
                        <legend className="c-label">
                            <span className="c-label-text" dangerouslySetInnerHTML={{__html: filteredConsents[consentElem].currConsentObj.subtext}} />
                        </legend>
                    </fieldset>
            }
            elemToRenderArr.push(elemToRender);
        }
        return (
            <div className="form-row">
                <div className="form-group form-group-opts">
                    <ol className="list list-opt">
                        <li className='item'>
                            {elemToRenderArr}
                        </li>
                    </ol>
                </div>
            </div>
        );
    }

    consentPreFlaggedCheck(channelsKeys, consentObj) {
        // if consent has channels, check if at least one channel is preFlagged , if so set consent as preFlagged
        if(channelsKeys && channelsKeys.length > 0) {
            for(let chKey of channelsKeys) {
                if (consentObj.channels[chKey].preflag) return true;
            }
        }
        // consent has not channel, check DB value to determinate if it's preFlagged
        return !!consentObj.preflag
    }

    consentFieldRenderBuilder(fieldName, errors, touched, isPreFlagged, channelKeys, isHidden, consentName, consentObj, channelName) {
        if (!channelKeys || channelKeys.length === 0) {
            if (!isPreFlagged) {
                return (
                    <Field type="checkbox" name={fieldName} id={fieldName}
                           className={'form-check-input c-checkbox' + (errors[fieldName] && touched[fieldName] ? ' is-invalid' : '')}
                           style={{ display: isHidden ? 'none' : 'block' }} />
                )
            } else {
                return (
                    <Field type="checkbox" name={fieldName} id={fieldName}
                           className={'form-check-input c-checkbox' + (errors[fieldName] && touched[fieldName] ? ' is-invalid' : '')}
                           style={{ display: isHidden ? 'none' : 'block' }}
                           checked={this[fieldName]}
                           onClick={e => { this.consentPreFlaggedToggle(e, fieldName) }} />
                )
            }
        }
        return (
            <ol className="list list-opt list-opt-inline">
                <li className="item">
                    <div className="form-check">
                        <label className="c-label">
                            <Field type="radio" id={fieldName + "_positive"} name={fieldName} className='c-radio'
                                   onClick={event => { this.channelsRadioClickHandler(event, fieldName, consentName, channelKeys, true, isPreFlagged) }}
                                   checked={(isPreFlagged && !this.channelsPreflagChanged[fieldName]) ? true : null} />
                            <span className="c-label-text" htmlFor={fieldName + "_positive"}>{this.channelCheckboxLabelManager(consentObj, channelName, true)}</span>
                        </label>
                    </div>
                </li>
                <li className="item">
                    <div className="form-check">
                        <label className="c-label">
                            <Field type="radio" id={fieldName + "_negative"} name={fieldName} className='c-radio'
                                   onClick={event => { this.channelsRadioClickHandler(event, fieldName, consentName, channelKeys, false, isPreFlagged) }}
                                   checked={(isPreFlagged && !this.channelsPreflagChanged[fieldName]) ? false : null} />
                            <span className="c-label-text" htmlFor={fieldName + "_negative"}>{this.channelCheckboxLabelManager(consentObj, channelName, false)}</span>
                        </label>
                    </div>
                </li>
            </ol>
        )
    }

    channelsRadioClickHandler(event, filedName, consentName, channelKeys, isPositive, isPreFlagged) {
        event.stopPropagation();
        this.channelsValues[filedName] = !!isPositive;
        if (isPreFlagged) {
            this.channelsPreflagChanged[filedName] = true;
        }
        this.channelConsentClickMan(event, consentName, channelKeys);
    }

    channelConsentClickMan(event, consentName, channelKeys) {
        // iterate all channels for that consent. If at least one channel is true set consent=true, otherwise set consent=false
        event.stopPropagation();
        let consentDomElement = document.getElementById(consentName);       // get hidden consent's checkbox
        if(!consentDomElement) {
            console.warn(`Warning: no DOM element found with id ${consentName}`);
            return;
        }
        for(let chKey of channelKeys) {
            let channelPositiveName = this.channelPpuNameBuilder(consentName, chKey) + "_positive";
            let channelPositiveDomElement = document.getElementById(channelPositiveName);
            if (channelPositiveDomElement && channelPositiveDomElement.checked) {
                if (!consentDomElement.checked) consentDomElement.click();
                return;
            }
        }
        if (consentDomElement.checked) consentDomElement.click();
    }

    consentErrorRenderBuilder(consentName) {
        return <ErrorMessage name={consentName} component="div" className="invalid-feedback"/>
    }

    channelTextManager(chObj) {
        // check if channel has text col and it's not null and it's not 'CHANNELIZED_CONSENT' show the value. Otherwise we'll show empty string
        if(chObj && chObj.hasOwnProperty("text") && chObj.text && chObj.text != 'CHANNELIZED_CONSENT') return chObj.text;
        return "";
    }

    channelToRenderBuilder(chObj, chName, consentName, errors, touched, channelsKeys, consentObj) {
        const channelToRender =
            <li className="item">
                <fieldset className="u-fieldset u-fieldset-opt">
                    <legend className="c-label-opts" htmlFor={this.channelPpuNameBuilder(consentName, chName)}>
                        <span className="c-label-opts__text" dangerouslySetInnerHTML={{ __html: this.channelTextManager(chObj) }}></span>
                    </legend>

                    {this.consentFieldRenderBuilder(this.channelPpuNameBuilder(consentName, chName), errors, touched, !!chObj.preflag, channelsKeys, false, consentName, consentObj, chName)}
                </fieldset>
            </li>
        return channelToRender;
    }

    channelsValuesCheck(values) {
        let chValuesKeys;
        if (this.channelsValues && (chValuesKeys = Object.keys(this.channelsValues)).length > 0) {
            let valuesClone = { ...values };
            chValuesKeys.forEach(chKey => {
                valuesClone[chKey] = this.channelsValues[chKey];
            });
            return valuesClone;
        }
        return values;
    }

    areThereConsentsInThisStep(stepToCheck) {
        if (!stepToCheck) return false;
        let stepsKeys = Object.keys(this.state.consentsFetched);
        for (let stepKey of stepsKeys) {
            if (this.state.consentsFetched[stepKey].hasOwnProperty("stepShow")
                && this.state.consentsFetched[stepKey].stepShow === stepToCheck && stepKey !== 'c0') {
                // at least one consent is shown in this step, return true and break the cycle
                return true;
            }
        }
        return false;
    }

    zipHandler(country) {
        switch(country) {
            case 'PT': {
                return /^[1-9][0-9]{3}[.-\s]?[0-9]{3}$/;
            }
            case "NO":
            case 'CH':
            case 'BG':
            case 'HU':
            case 'AT': {
                return /^[0-9]{4}$/;
            }
            case 'RO': {
                return /^[0-9]{6}$/;
            }
            case 'CZ': {
                return /^[0-9]{3}[\s]?[0-9]{2}$/;
            }
            case 'PL': {
                return /^[0-9]{2}[-][0-9]{3}$/;
            }
            case 'GB': {
                return /^(([A-Z]{1,2}[\d][A-Z\d]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) [\d][A-Z]{2}|BFPO [\d]{1,4}|(KY[\d]|MSR|VG|AI)[ -][\d]{4}|[A-Z]{2} ?[\d]{2}|GE CX|GIR 0A{2}|SAN TA1|W1T 1F)$/;
            }
            case 'IE': {
                return /^([AC-FHKNPRTV-Y]{1}[cd ..0-9]{2}|D6W)[\u0020]?[0-9AC-FHKNPRTV-Y]{4}$/i;
            }
            case 'NIR': {
                return /^(BT)[1-9]{1,2}|([ -][1-9][A-Z]{2})$/;
            }
            case 'NL': {
                return /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
            }
            case 'FR': {
                return /^[0-9]{5}$/;
            }
            case 'BE': {
                return /^[0-9]{4}$/;
            }
            case 'DK': {
                return /^[0-9]{3,4}$/;
            }
            default: {
                return /^[0-9]{5}$/;
            }
        }
    }

    //since annoLaurea must be a number OR null, AND its default value is '', transform it to null when encountered
    replacer(k, v) {
        if (k === 'annoLaurea' & v === '') {
            return null;
        }
        return v;
    }

    footerLoc() {
        let localized = i18n.t('footer');
        let localizedP = this.props.profType != null ? i18n.t('footer_'+this.props.profType) : false;
        if(localizedP && i18n.exists('footer_'+this.props.profType) ) {
            document.getElementById('footerText').innerHTML = localizedP;
        } else if (localized && i18n.exists('footer')) {
            document.getElementById('footerText').innerHTML = localized;
        }
    }

    errorRender() {
        return (<>
            <div className="o-headers">
                <div className="h-img">
                    <img alt='Error' src={OBJECTS_PATH.PICTURES.GENERIC_ERROR_ICON} id="img" />
                </div>
                <h1 className="h h-1">{i18n.t('professional.errorMessages.genericError')}</h1>
            </div>
        </>);
    }

    nationalIdCheck() {
        if((!this.state.isEditMode && this.props.nationalid_required) || (this.state.isEditMode && this.props.nationalid_edit_required)) {
            switch (this.parsedLanguage) {
                case SUPPORTED_LANG.ITALIAN:
                    return Yup.string().nullable().required(i18n.t('econsent.form.campoObbligatorio')).test('cf', i18n.t('professional.fiscalCodeError'), val => CodiceFiscale.check(val))
                case SUPPORTED_LANG.POLISH: //seven digits
                    let plReg;
                    if(this.props.profType === 'U'){
                        plReg = /^[0-9]{10}$/;
                    } if(this.props.profType === 'P'){
                    plReg = /^[0-9]{8}$/;
                }
                else {
                    plReg = /^[0-9]{7}$/;
                }
                    return Yup.string().nullable().required(i18n.t('econsent.form.campoObbligatorio')).matches(plReg, i18n.t('professional.fiscalCodeError'));
                default:
                    return Yup.string().nullable().required(i18n.t('econsent.form.campoObbligatorio'));
            }
        }
        return Yup.string().nullable();
    }

    submitBtnManager(handleSubmit) {
        if (this.parsedLanguage === SUPPORTED_LANG.POLISH) {
            this.polishC4Check(handleSubmit);
        }
        else {
            handleSubmit();
        }
    }

    polishC4Check(handleSubmit) {
        if(document.getElementById("c9") && !document.getElementById("c9").checked) {
            Modal.setAppElement('#page');
            this.setState({isModalOpen: true});
            document.getElementsByTagName("html")[0].classList.add("is-aid-dialog");
        } else {
            handleSubmit();
        }
    }

    closeModal(doSubmit, handleSubmit) {
        this.setState({isModalOpen: false});
        document.getElementsByTagName("html")[0].classList.remove("is-aid-dialog");
        if(doSubmit) handleSubmit();
    }

    i18nByProfType(key){
        return !i18n.t(`${key}_` + this.props.profType).includes(`${key}_`) ?
            i18n.t(`${key}_` + this.props.profType) :
            i18n.t(`${key}`)
    }

    subLanguageMenuRender() {
        if(!this.props.subLanguagesInfo) return;
        this.props.subLanguagesInfo.forEach(subLanInfo => {
            let optObj = {};
            optObj.label = subLanInfo.label;
            optObj.key = subLanInfo.sub_culture;
            if(optObj.key === this.parsedLanguage.CULTURE_CODE)
                this.subLanPlaceHolder = optObj.label;
            this.subLangOptions.push(optObj);
        });
    }

    subLanguageDefaultMan() {
        let defaultSubLan;
        for(let subLanInfo of this.props.subLanguagesInfo) {
            if(subLanInfo.isDefaultSubLan) defaultSubLan = subLanInfo
        }
        if(this.parsedLanguage && defaultSubLan.sub_culture && this.parsedLanguage.CULTURE_CODE !== defaultSubLan.sub_culture)
            this.subLanguageEventHandler(defaultSubLan.sub_culture)
    }

    subLanguageEventHandler(subLanKey) {
        // to avoid all reload-related issues we use 'sublan' param workaround
        let redirectUrl = new URL(window.location)
        redirectUrl.searchParams.set('sublan', subLanKey);
        window.location = redirectUrl;
    }

    subLanguageCountryEventHandler(subLanForced) {
        // if subLanguage is forced or it has been chosen by user, we must update 'country' (nazione) field too
        if(!this.props.subLanguagesInfo) return;        // no subLanguage for this domain, return
        for(let subLan of this.props.subLanguagesInfo) {
            if(subLan.sub_culture && subLan.sub_culture === subLanForced && subLan.country && subLan.country !== "") this.countryForced = subLan.country;
        }
    }

    mandatoryFieldsRender(text = "*", hiddenText = 'professional.campoObbligatorio') {
        return <span className="form-input-indicator"><span className="sr-only u-hidden">{this.i18nByProfType(hiddenText)} </span>{text}</span>;
    }

    dynamicFieldsRender({field, errors, touched, additionalProps = {}}) {
        switch (field) {
            case"cap": {
                return <React.Fragment>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="cap"
                                   className="c-label">{i18n.t('professional.cap')}
                                {this.mandatoryFieldsRender()}
                            </label>
                            <Field name="cap" type="text"
                                   className={'form-control c-input' + (errors.cap && touched.cap ? ' is-invalid' : '')}/>
                            <ErrorMessage name="cap" component="div"
                                          className="invalid-feedback"/>
                        </div>
                    </div>
                </React.Fragment>;
            }
            case"firstName": {
                return <React.Fragment>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="firstName" className="c-label">{i18n.t('econsent.form.nome')}
                                {this.mandatoryFieldsRender()}
                            </label>
                            <Field maxLength="254" name="firstName" id="firstName" type="text"
                                   className={'form-control c-input' + (errors.firstName && touched.firstName ? ' is-invalid ' : ' ') + (this.state.isEditMode ? 'disabled' : '')}
                                   disabled={this.state.isEditMode ? true : false}/> {/* TODO changed from currentUser to isEditMode, to check */}
                            <ErrorMessage name="firstName" component="div" className="invalid-feedback"/>
                        </div>
                    </div>
                </React.Fragment>;
            }
            case"lastName": {
                return <React.Fragment>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="lastName" className="c-label">{i18n.t('econsent.form.cognome')}
                                {this.mandatoryFieldsRender()}
                            </label>
                            <Field maxLength="254" name="lastName" type="text"
                                   className={'form-control c-input' + (errors.lastName && touched.lastName ? ' is-invalid ' : ' ') + (this.state.isEditMode ? 'disabled' : '')}
                                   disabled={this.state.isEditMode ? true : false}/> {/* TODO changed from currentUser to isEditMode, to check */}
                            <ErrorMessage name="lastName" component="div" className="invalid-feedback"/>
                        </div>
                    </div>
                </React.Fragment>;
            }
            case"codiceFiscale": {
                return <React.Fragment>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="codiceFiscale" className="c-label">
                                {
                                    !i18n.t('econsent.form.codiceFiscale_' + this.props.profType).includes("econsent.form.codiceFiscale_") ?
                                        i18n.t('econsent.form.codiceFiscale_' + this.props.profType) : i18n.t('econsent.form.codiceFiscale')
                                }
                                {((!this.state.isEditMode && this.props.nationalid_required) || (this.state.isEditMode && this.props.nationalid_edit_required)) ?
                                    this.mandatoryFieldsRender()
                                    : ''}
                            </label>
                            <Field maxLength="99" name="codiceFiscale" type="text"
                                   className={'form-control c-input' + (errors.codiceFiscale && touched.codiceFiscale ? ' is-invalid' : '')}
                                   placeholder={!i18n.t('professional.cfTooltip_' + this.props.profType).includes("professional.cfTooltip_") ?
                                       i18n.t('professional.cfTooltip_' + this.props.profType) : i18n.t('professional.cfTooltip')}
                            />
                            <ErrorMessage name="codiceFiscale" component="div"
                                          className="invalid-feedback"/>
                        </div>
                    </div>
                </React.Fragment>;
            }
            case"email": {
                return <React.Fragment>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="email"
                                   className="c-label">{i18n.t('econsent.form.indirizzoe-mail')}
                                {this.mandatoryFieldsRender()}
                            </label>
                            <Field maxLength="254" name="email" type="text" id="email"
                                   className={'form-control c-input' + (errors.email && touched.email ? ' is-invalid' : '')}
                            />
                            <ErrorMessage name="email" component="div"
                                          className="invalid-feedback"/>
                        </div>
                    </div>
                </React.Fragment>;
            }
            case"emailrepeat": {
                return <React.Fragment>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="emailrepeat"
                                   className="c-label">{i18n.t('econsent.form.emailAddressRepeat')}
                                {this.props.domainInfo.emailrepeat_required ?
                                    <span>*</span> : ""}
                            </label>
                            <Field maxLength="254" name="emailrepeat" type="text"
                                   id="emailrepeat" autoComplete="nope"
                                   className={'form-control c-input' + (errors.emailrepeat && touched.emailrepeat ? ' is-invalid' : '')}/>
                            <ErrorMessage name="emailrepeat" component="div"
                                          className="invalid-feedback"/>
                        </div>
                    </div>
                </React.Fragment>;
            }
            case"consents":{
                return <React.Fragment>
                    <div className="form-row">
                        <div className="form-group form-group-opts">
                            <div className="form-check">
                                <Field type="checkbox" name="c0" id="c0"
                                       className={'form-check-input c-checkbox' + (errors.c0 && touched.c0 ? ' is-invalid' : '')}/>
                                <label className="c-label" htmlFor="c0"
                                       dangerouslySetInnerHTML={{
                                           __html: this.state.consentsFetched.c0.text +
                                               ReactDOMServer.renderToStaticMarkup(this.mandatoryFieldsRender())
                                       }}>
                                </label>
                                <ErrorMessage name="c0" component="div"
                                              className="invalid-feedback"/>
                            </div>
                        </div>
                    </div>
                </React.Fragment>;
            }
            case"specializzazione": {
                return <React.Fragment>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="specializzazione"
                                   className="c-label">{this.i18nByProfType('professional.specialization')}
                                {this.mandatoryFieldsRender()}
                            </label>
                            <Field as="select" name="specializzazione"

                                   className={'form-control specializzazione c-input c-input-select' + (errors.specializzazione && touched.specializzazione ? ' is-invalid' : '')}
                                   dangerouslySetInnerHTML={{__html: additionalProps.specLabel}}

                                   validate={this.validateSpec}/>
                            <ErrorMessage name="specializzazione" component="div"
                                          className="invalid-feedback"/>
                        </div>
                    </div>
                </React.Fragment>;
            }
            case"annoLaurea":{
                return <React.Fragment>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="annoLaurea"
                                   className="c-label">{i18n.t(this.profTypeKeysHandler('professional.graduationYear'))}
                                {/* {this.mandatoryFieldsRender()} */}
                            </label>
                            <Field name="annoLaurea" type="text"
                                   className={'form-control c-input' + (errors.annoLaurea && touched.annoLaurea ? ' is-invalid' : '')}/>
                            <ErrorMessage name="annoLaurea" component="div"
                                          className="invalid-feedback"/>
                        </div>
                    </div>
                </React.Fragment>;
            }
            case"luogoLavoro":{
                return <React.Fragment>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="luogoLavoro"
                                   className="c-label">{i18n.t(this.profTypeKeysHandler('professional.workPlace'))}
                                {(this.props.domainInfo.luogoLavoro_required && this.props.domainInfo.luogoLavoro_required === 1)
                                    ? this.mandatoryFieldsRender()
                                    : ''
                                }
                            </label>
                            <Field name="luogoLavoro" type="text"
                                   className={'form-control c-input' + (errors.luogoLavoro && touched.luogoLavoro ? ' is-invalid' : '')}/>
                            <ErrorMessage name="luogoLavoro" component="div"
                                          className="invalid-feedback"/>
                        </div>
                    </div>
                </React.Fragment>;
            }
            case"reparto": {
                return <React.Fragment>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="reparto"
                                   className="c-label">{i18n.t('professional.workUnit')}
                                {(this.props.domainInfo.reparto_required && this.props.domainInfo.reparto_required === 1)
                                    ? this.mandatoryFieldsRender()
                                    : ''
                                }
                            </label>
                            <Field name="reparto" type="text"
                                   className={'form-control c-input' + (errors.reparto && touched.reparto ? ' is-invalid' : '')}/>
                            <ErrorMessage name="reparto" component="div"
                                          className="invalid-feedback"/>
                        </div>
                    </div>
                </React.Fragment>;
            }
            case"indirizzo":{
                return <React.Fragment>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="indirizzo"
                                   className="c-label">{i18n.t(this.profTypeKeysHandler('professional.workAddress'))}
                                {this.mandatoryFieldsRender()}
                            </label>
                            <Field name="indirizzo" type="text"
                                   className={'form-control c-input' + (errors.indirizzo && touched.indirizzo ? ' is-invalid' : '')}/>
                            <ErrorMessage name="indirizzo" component="div"
                                          className="invalid-feedback"/>
                        </div>
                    </div>
                </React.Fragment>;
            }
            case"citta": {
                return <React.Fragment>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="citta"
                                   className="c-label">{i18n.t('professional.workCity')}
                                {this.mandatoryFieldsRender()}
                            </label>
                            <Field name="citta" type="text"
                                   className={'form-control c-input' + (errors.citta && touched.citta ? ' is-invalid' : '')}/>
                            <ErrorMessage name="citta" component="div"
                                          className="invalid-feedback"/>
                        </div>
                    </div>
                </React.Fragment>;
            }
            case"provincia":{
                return <React.Fragment>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="provincia"
                                   className="c-label">{i18n.t('professional.province')}
                                {/* {this.mandatoryFieldsRender()} */}
                            </label>
                            <Field as="select" name="provincia"
                                   className={'form-select provincia form-control c-input' + (errors.provincia && touched.provincia ? ' is-invalid' : '')}
                                   dangerouslySetInnerHTML={{__html: additionalProps.provinces}}/>
                            <ErrorMessage name="provincia" component="div"
                                          className="invalid-feedback"/>
                        </div>
                    </div>
                </React.Fragment>;
            }
            case"phone":{
                return <React.Fragment>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="phone"
                                   className="c-label">{this.i18nByProfType("professional.phoneNum")}
                                {(this.props.domainInfo.phone_required && this.props.domainInfo.phone_required === 1)
                                    ? this.mandatoryFieldsRender()
                                    : ""}
                            </label>
                            <Field name="phone" type="text"
                                   className={'form-control c-input' + (errors.phone && touched.phone ? ' is-invalid' : '')}/>
                            <ErrorMessage name="phone" component="div"
                                          className="invalid-feedback"/>
                        </div>
                    </div>
                </React.Fragment>;
            }
            case"pharmacyName":{
                return <React.Fragment>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="phone"
                                   className="c-label">{this.i18nByProfType("professional.pharmacyName")}
                                {(this.props.domainInfo.pharmacyName_required && this.props.domainInfo.pharmacyName_required === 1)
                                    ? this.mandatoryFieldsRender()
                                    : ""}
                            </label>
                            <Field name="pharmacyName" type="text"
                                   className={'form-control c-input' + (errors.pharmacyName && touched.pharmacyName ? ' is-invalid' : '')}/>
                            <ErrorMessage name="pharmacyName" component="div"
                                          className="invalid-feedback"/>
                        </div>
                    </div>
                </React.Fragment>;
            }
            default: {

            }
        }
    }



    render() {
        const { history } = this.props;
        // console.log(this.props);
        // console.log(this.state);
        let subLanForced = new URL(window.location.href).searchParams.get('sublan');
        if(subLanForced) {
            // priority to 'subLanguage' param
            this.parsedLanguage = parseLanguage(subLanForced);
            this.subLanguageCountryEventHandler(subLanForced);
        } else if(this.props.forcedLanguage) {
            // if 'forcedLanguage' means that this domain must be shown to selected language only
            this.parsedLanguage = parseLanguage(this.props.forcedLanguage);
        }
        else this.parsedLanguage = parseLanguage(this.props.match.params.lan);   // no forcedLanguage, read language from URL
        if(!this.parsedLanguage) {
            // language not recognized, fallback and redirect to default one
            let localizedPath = this.props.match.path.replace(":lan?", DEFAULT_LANGUAGE.LAN_CODE);
            return <Redirect to={localizedPath} />
        }

        if(!this.state.translateCompleted) {
            return (
                <div className="o-headers">
                    <div className="h-img">
                        <img id="img" src={OBJECTS_PATH.PICTURES.LOADING_GIF} alt='Loading...' width="130px" height="130px" />
                    </div>
                </div>
            );
        }

        if(this.props.subLanguagesInfo && !subLanForced) this.subLanguageDefaultMan();

        //check if redirect target has already been set on landing, otherwise search in params; will be null if not set
        if (!this.target) {
            const isValidUrl = urlString=> {
                var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
                    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
                    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
                    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
                    '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
                return !!urlPattern.test(urlString);
            }
            let targetUrl = new URL(window.location.href).searchParams.get('target');
            if(isValidUrl(targetUrl)) {
                this.target = targetUrl;
            }
        }
        this.event_id = new URL(window.location.href).searchParams.get('eventid');

        let intFlagCheck = new URL(window.location.href).searchParams.get('intlflag');
        if(intFlagCheck && intFlagCheck === "true") {
            this.intlflag = true;
        }
        let intlTypeParam = new URL(window.location.href).searchParams.get('intlType');
        if(intlTypeParam && intlTypeParam !== "") {
            this.intl_flow_type = intlTypeParam;
        }

        let url = new URL(window.location.href)
        let sourceParamByUrl = url.searchParams.get("source");
        if(sourceParamByUrl && sourceParamByUrl.length > 0) this.source = sourceParamByUrl;     // if source is a URL param
        else if(this.props.match.params.source) this.source = this.props.match.params.source;   // if source found on URL set it
        else if(this.props.source) this.source = this.props.source;                             // if source not in URL get it from DB infoI
        else this.source = "ND"                                                                 // if source not in URL and not in DB set ND

        let specLabel, provinces;
        if (this.state.step === STEP_INFO.DEEP_SEARCH) {
            specLabel = '<option value=""></option>';
            this.state.specialization.sort(function (a, b) {
                var textA = a['label'].toUpperCase();
                var textB = b['label'].toUpperCase();
                return textA.localeCompare(textB);
            }).forEach(e => {
                specLabel += `<option value="${e.externalId}">${e['label']}</option>`
            });
            provinces = '<option value=""></option>';
            this.state.provinces.sort(function (a, b) {
                var textA = a['label'].toUpperCase();
                var textB = b['label'].toUpperCase();
                return textA.localeCompare(textB);
            }).forEach(e => {
                provinces += `<option value="${e.code}">${e.label}</option>`
            });
        }

        this.footerLoc();

        if(Object.keys(this.state.consentsFetched).length === 0) {
            // while frontend is fetching consents, show loading gif
            return (
                <div className="o-headers">
                    <div className="h-img">
                        <img id="img" alt='Loading...' src={OBJECTS_PATH.PICTURES.LOADING_GIF} style={{ width: '130px', height: '130px' }} />
                    </div>
                </div>
            );
        } else if(Object.keys(this.state.consentsFetched).length === 1 && this.state.consentsFetched.error) {
            return (this.errorRender());
        }
        else {
            // consents fetched, show time
            let validationObjUserDataStep = Yup.object().shape(this.validationJsonBuilder(STEP_INFO.USER_DATA, this.parsedLanguage.NATION_CODE));
            let validationObjDeepSearchStep = Yup.object().shape(this.validationJsonBuilder(STEP_INFO.DEEP_SEARCH, this.parsedLanguage.NATION_CODE));
            let validationObjIntlFlowStep = Yup.object().shape(this.validationJsonBuilder(STEP_INFO.INTL_FLOW, this.parsedLanguage.NATION_CODE));
            let validationObjConsentPromptStep = Yup.object().shape(this.validationJsonBuilder(STEP_INFO.CONSENT_PROMPT, this.parsedLanguage.NATION_CODE));
            // console.log(validationObjUserDataStep)
            let initValues = this.initValuesJsonBuilder();
            return (
                <Formik
                    initialValues={initValues}
                    enableReinitialize
                    validationSchema={
                        (this.state.step === STEP_INFO.USER_DATA && validationObjUserDataStep)
                        || (this.state.step === STEP_INFO.DEEP_SEARCH && validationObjDeepSearchStep)
                        || (this.state.step === STEP_INFO.INTL_FLOW && validationObjIntlFlowStep)
                        || (this.state.step === STEP_INFO.CONSENT_PROMPT && validationObjConsentPromptStep)
                    }

                    onSubmit={(values, { setSubmitting } ) => {
                        let url = this.props.serviceUrl;
                        values.consentsInfo = this.state.consentsInfo;
                        values.specializzazione = this.specializzazioneSelected;
                        values.provincia = this.provinciaSelected;
                        if (this.intlflag) values["terms_intl"] = false;             // to track down users that skip intl flow consents, we set it to false if international step is required. This record we'll be updated to true when user's will check the intl flag
                        if (this.intl_flow_type) values["intl_flow_type"] = this.intl_flow_type;
                        if(this.target) values["target"] = this.target;
                        if(this.event_id) values["event_platform_id"] = this.event_id;
                        let chParsedValues = this.channelsValuesCheck(values);
                        if(this.countryForced) values['nazione'] = this.countryForced;  // if countryForced, it means that field 'nazione' has been updated by subLanguage
                        if (this.state.isEditMode) {
                            chParsedValues.skipSearch = true;
                            chParsedValues.individualEid = this.individualEid
                        }

                        // force api_channel to AID to save in dsa_channel DSA task
                        chParsedValues["api_channel"] = "AID";
                        let data = JSON.stringify(chParsedValues, null, 2);
                        this.setState({isFetchProcessing: true});

                        if (this.state.step === STEP_INFO.USER_DATA) {
                            fetch(url, {
                                credentials: 'same-origin', // 'include', default: 'omit'
                                method: 'POST',             // 'GET', 'PUT', 'DELETE', etc.
                                body: data, // Use correct payload (matching 'Content-Type')
                                headers: new Headers({
                                    'Content-Type': 'application/json'
                                })
                            })
                                .then(response => {
                                    if (!response.ok) {
                                        console.log(response.status)
                                        if (response.status === 418) {
                                            this.setState({deepSearchRequired: true})
                                        } else if(response.status === 419) {
                                            this.setState({consentPromptRequired: true})
                                        } else {
                                            return response.json()
                                        }
                                    }
                                    return response.json()
                                })
                                .then(data => {
                                    setSubmitting(false);
                                    if (data.message) {
                                        alert(data.message);
                                    } else if (this.state.deepSearchRequired && data.hasOwnProperty('provinces') && data.hasOwnProperty('specialization')) {
                                        let message = '';
                                        if (!data.specialization[0]['label']) {
                                            console.log(`WARNING: no specializations for ${this.parsedLanguage.NATION_CODE}`)
                                            message = i18n.t("professional.errorMessages.workInProgress");
                                            alert(message);
                                            this.setState({deepSearchRequired: false})
                                        } else if (!data.provinces[0][`label`]) {
                                            console.log(`WARNING: no provinces for ${this.parsedLanguage.NATION_CODE}`)
                                            message = i18n.t("professional.errorMessages.workInProgress");
                                            alert(message);
                                            this.setState({deepSearchRequired: false})
                                        } else {
                                            this.setState({ provinces: data.provinces, specialization: data.specialization, step: STEP_INFO.DEEP_SEARCH });
                                            values.step = STEP_INFO.DEEP_SEARCH
                                        }
                                    } else if (this.state.consentPromptRequired) {
                                        this.setState({step: STEP_INFO.CONSENT_PROMPT});
                                        if(data.hasOwnProperty("userObj")) {
                                            Object.keys(data.userObj).forEach(userInfo => {
                                                values[userInfo] = data.userObj[userInfo];
                                            });
                                        }
                                        values.step = STEP_INFO.CONSENT_PROMPT;
                                    } else if (data.key) {
                                        let message = i18n.t("professional.errorMessages." + data.key);
                                        let errorCodeIntro = !i18n.t('errorCodeIntro').includes('errorCodeIntro') ?
                                            i18n.t('errorCodeIntro') :
                                            "Error Code";
                                        if(data.key === "userDuplicator") {
                                            // if cmr user duplicate, add CRM_USER_DUPLICATE error code
                                            message += " (" + errorCodeIntro + ": " +  ERROR_CODES.REGISTRATION.CRM_USER_DUPLICATE + ")";
                                        } else if(data.key === "emailDuplicate") message += " (" + errorCodeIntro + ": " +  ERROR_CODES.REGISTRATION.EMAIL_DUPLICATE + ")";
                                        else if(data.key === "emailDuplicateCrmNotHcp") message =  i18n.t("professional.errorMessages.emailDuplicate") + " (" + errorCodeIntro + ": " +  ERROR_CODES.REGISTRATION.CRM_EMAIL_DUPLICATED_NOT_HCP + ")";
                                        else if(data.key === "fiscalCodeDuplicate") message += " (" + errorCodeIntro + ": " +  ERROR_CODES.REGISTRATION.NATIONAL_ID_DUPLICATE + ")";
                                        else if(data.key === "genericError") message += " (" + errorCodeIntro + ": " +  ERROR_CODES.REGISTRATION.GENERIC_ERROR + ")";
                                        else if(data.key === "duplicatedEid") {
                                            // no text for 'duplicatedEid', show generic message with specific error code
                                            message = i18n.t("professional.errorMessages.genericError");
                                            message += " (" + errorCodeIntro + ": " +  ERROR_CODES.REGISTRATION.DUPLICATED_EID + ")";
                                        }
                                        else if(data.key === "oneKeySearchFail") {
                                            message = i18n.t("professional.errorMessages.genericError");
                                            message += " (" + errorCodeIntro + ": " +  ERROR_CODES.REGISTRATION.ONEKEY_SEARCH_FAIL + ")";
                                        }
                                        else if(data.key === "vrReqError") {
                                            message = i18n.t("professional.errorMessages.genericError");
                                            message += " (" + errorCodeIntro + ": " +  ERROR_CODES.REGISTRATION.ONEKEY_VR_FAIL + ")";
                                        }
                                        else if(data.key === "provAndSpecFail") {
                                            message = i18n.t("professional.errorMessages.genericError");
                                            message += " (" + errorCodeIntro + ": " +  ERROR_CODES.REGISTRATION.SPEC_AND_PROV_FAIL + ")";
                                        }
                                        this.errorText = message;
                                        this.setState({ isErrorModalOpen: true });
                                        document.getElementsByTagName("html")[0].classList.add("is-aid-dialog");
                                    } else {
                                        if(this.event_id) history.push(this.props.domainInfo.eventplatformcons_redirect_path, {aidCode: data.aid_code, eventId: this.event_id});
                                        else if(this.props.changepass_flow && this.props.changepass_path) {
                                            // if changepass_flow is ON and changepass_path not null redirect user to change password flow
                                            let changepass_path = this.props.changepass_path + "?sublan=" + ((subLanForced != null) ? subLanForced : this.parsedLanguage.CULTURE_CODE);
                                            history.push(changepass_path, {change_pass_token: data.change_pass_token, status: data.statoMedico, target: this.target, signupLanguage: this.parsedLanguage});
                                        }
                                        else history.push(this.landingUrl, {status: data.statoMedico, target: this.target, signupLanguage: this.parsedLanguage, aidcode: data.aid_code});  //send information about registration status and target (if present) to next screen
                                    }
                                })
                                .catch(async error => {
                                    // Something went wrong while fetching
                                    await this.noInternetConnectionCheck(error, url);
                                    setSubmitting(false);
                                })
                                .finally(() => {
                                    this.setState({isFetchProcessing: false})
                                });
                        } else if (this.state.step === STEP_INFO.DEEP_SEARCH) {
                            fetch(url, {
                                credentials: 'same-origin', // 'include', default: 'omit'
                                method: 'POST',             // 'GET', 'PUT', 'DELETE', etc.
                                body: data, // Use correct payload (matching 'Content-Type')
                                headers: new Headers({
                                    'Content-Type': 'application/json'
                                })
                            })
                                .then(response => {
                                    if(response.status === 419) {
                                        this.setState({consentPromptRequired: true})
                                    } else if (!response.ok && response.status !== 501) {
                                        throw Error(response.statusText)
                                    }
                                    return response.json()
                                })
                                .then(data => {
                                    setSubmitting(false);
                                    if (this.state.consentPromptRequired) {
                                        this.setState({step: STEP_INFO.CONSENT_PROMPT});
                                        if(data.hasOwnProperty("userObj")) {
                                            Object.keys(data.userObj).forEach(userInfo => {
                                                values[userInfo] = data.userObj[userInfo];
                                            });
                                        }
                                        values.step = STEP_INFO.CONSENT_PROMPT;
                                    } else if (data.key) {
                                        let message = i18n.t("professional.errorMessages." + data.key);
                                        if(message.includes("professional.errorMessages.")) message = "Generic Error";
                                        alert(message);
                                    } else {
                                        if(this.event_id) history.push(this.props.domainInfo.eventplatformcons_redirect_path, {aidCode: data.aid_code, eventId: this.event_id});
                                        else if(this.props.changepass_flow && this.props.changepass_path) {
                                            // if changepass_flow is ON and changepass_path not null redirect user to change password flow
                                            let changepass_path = this.props.changepass_path + "?sublan=" + ((subLanForced != null) ? subLanForced : this.parsedLanguage.CULTURE_CODE);
                                            history.push(changepass_path, {change_pass_token: data.change_pass_token, status: data.statoMedico,
                                                target: this.target, signupLanguage: this.parsedLanguage, changepass_path: this.props.changepass_path});
                                        }
                                        else history.push(this.landingUrl, {status: data.statoMedico, target: this.target, signupLanguage: this.parsedLanguage, aidcode: data.aid_code}); //send information about registration status and target (if present) to next screen
                                    }
                                })
                                .catch(async error => {
                                    await this.noInternetConnectionCheck(error, url);
                                    setSubmitting(false);
                                })
                                .finally(() => {
                                    this.setState({isFetchProcessing: false})
                                });
                        } else if (this.state.step === STEP_INFO.CONSENT_PROMPT) {
                            fetch(url, {
                                credentials: 'same-origin',
                                method: 'POST',
                                body: data,
                                headers: new Headers({ 'Content-Type': 'application/json' })
                            }).then(response => {
                                if (!response.ok && response.status !== 501) throw Error(response.statusText);
                                return response.json()
                            }).then(data => {
                                setSubmitting(false);
                                if (data.key) {
                                    let message = i18n.t("professional.errorMessages." + data.key);
                                    if(message.includes("professional.errorMessages.")) message = "Generic Error";
                                    alert(message);
                                } else {
                                    if(this.event_id) history.push(this.props.domainInfo.eventplatformcons_redirect_path, {aidCode: data.aid_code, eventId: this.event_id});
                                    else if(this.props.changepass_flow && this.props.changepass_path) {
                                        // if changepass_flow is ON and changepass_path not null redirect user to change password flow
                                        let changepass_path = this.props.changepass_path + "?sublan=" + ((subLanForced != null) ? subLanForced : this.parsedLanguage.CULTURE_CODE);
                                        history.push(changepass_path, {change_pass_token: data.change_pass_token, status: data.statoMedico, target: this.target, signupLanguage: this.parsedLanguage});
                                    }
                                    else history.push(this.landingUrl, {status: data.statoMedico, target: this.target, signupLanguage: this.parsedLanguage, aidcode: data.aid_code}); //send information about registration status and target (if present) to next screen
                                }
                            }).catch(async error => {
                                await this.noInternetConnectionCheck(error, url);
                                setSubmitting(false);
                            }).finally(() => {
                                this.setState({isFetchProcessing: false})
                            });
                        } else if (this.state.step === STEP_INFO.INTL_FLOW) {
                            /* WARNING: INTL FLOW HAS BEEN DISABLED HAS REQUIRED BY CANZONETTA, AID-449 */
                            setSubmitting(false);
                            fetch(this.props.intl_flow_service_url, {
                                credentials: 'same-origin', // 'include', default: 'omit'
                                method: 'POST',             // 'GET', 'PUT', 'DELETE', etc.
                                body: JSON.stringify({ reqType: "set_terms_intl", userEmail: values.email, value: true }), // Use correct payload (matching 'Content-Type')
                                headers: new Headers({ 'Content-Type': 'application/json' })
                            }).then(response => {
                                if(!response.ok && response.status < 500) {
                                    // generic error generated by frontend
                                    let errorCodeIntro = !i18n.t('errorCodeIntro').includes('errorCodeIntro') ?
                                        i18n.t('errorCodeIntro') :
                                        "Error Code";
                                    this.errorText = i18n.t("econsent.serverError") + " (" + errorCodeIntro + ": IF300)";
                                    this.setState({ isErrorModalOpen: true });
                                    document.getElementsByTagName("html")[0].classList.add("is-aid-dialog");
                                    return null;
                                }
                                return response.json();
                            })
                                .then(data => {
                                    setSubmitting(false);
                                    if(!data) return null;
                                    if(data.serverError === true) {
                                        this.errorText = i18n.t("econsent.serverError");
                                        let errorCodeIntro = !i18n.t('errorCodeIntro').includes('errorCodeIntro') ?
                                            i18n.t('errorCodeIntro') :
                                            "Error Code";
                                        if(data.errCode) this.errorText += " (" + errorCodeIntro + ": " + data.errCode + ")";
                                        this.setState({ isErrorModalOpen: true });
                                        document.getElementsByTagName("html")[0].classList.add("is-aid-dialog");
                                        return null;
                                    }
                                    if(this.props.successLanding) {
                                        history.push(this.props.successLanding, {status: this.state.statoMedico, target: this.target, profType: this.props.profType});
                                    }
                                }).catch(async error => {
                                await this.noInternetConnectionCheck(error, url);
                            })
                                .finally(() => {
                                    this.setState({isFetchProcessing: false})
                                });
                        }
                    }}
                >
                    {({errors, status, touched, isSubmitting, handleSubmit}) => (<>
                        <Helmet>
                            <title>{i18n.t(this.profTypeKeysHandler('professional.tabTitle'))}</title>
                            <meta name="description" content={i18n.t(this.profTypeKeysHandler('professional.tabTitle'))}/>
                        </Helmet>

                        {this.props.subLanguagesInfo && this.state.step === STEP_INFO.USER_DATA ?
                            <div className="form-row">
                                <div className="form-group">
                                    {/* LOCALIZE: */}
                                    <label htmlFor="sublanguages" className="c-label">{i18n.t("professional.choose_your_country")}
                                        {this.mandatoryFieldsRender()}</label>
                                    <select id="sublanguages" name="sublanguages" className="c-input c-input-select">{/* removed onChange={(event) => { this.subLanguageEventHandler(event.key) }} */}
                                        {this.subLangOptions.map((opt) => {
                                            if (opt.label === this.subLanPlaceHolder) return <option value={opt.key} selected>{opt.label}</option>
                                            else return <option value={opt.key}>{opt.label}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                            : ""
                        }

                        <div id="step_info" style={{display:'none'}}>{this.state.step}</div>
                        {/*INSERIRE BUTTON QUI*/}


                        <Form id="angform">
                            <fieldset className="c-fieldset">
                                <div className="u-wrap-fields">
                                    <div className="o-headers">
                                        <h1 className="h h-1">
                                            {this.state.step === STEP_INFO.CONSENT_PROMPT
                                                ? i18n.t('professional.preferencePrivacyTitle')
                                                : i18n.t(this.profTypeKeysHandler('professional.pageTitle'))
                                            }
                                        </h1>
                                    </div>
                                    {this.state.step === STEP_INFO.USER_DATA && (<>
                                        <div className="o-prose">
                                            <div className="e-content">
                                                <p className="f-1"
                                                   dangerouslySetInnerHTML={{__html: i18n.t('professional.intro_professional_' + this.props.profType)}}></p>
                                            </div>
                                        </div>

                                        <div className="validation-summary-valid">
                                            <ul>
                                                <li style={{display: "none"}}></li>
                                            </ul>
                                        </div>
                                        {/* /.validation-summary-valid */}

                                        <Field name="step" type="hidden"/>

                                        {/* NAME */}
                                        {(DYNAMIC_DISPLAY_CONFIG_ARRAY.firstName.step.length > 0)
                                            ?
                                            ((DYNAMIC_DISPLAY_CONFIG_ARRAY.firstName.step.includes(STEP_INFO.USER_DATA))
                                                    ? (this.dynamicFieldsRender({field: "firstName", errors, touched}))
                                                    : null
                                            )
                                            :  (this.dynamicFieldsRender({field: "firstName", errors, touched}))
                                        }

                                        {/* SURNAME */}
                                        {(DYNAMIC_DISPLAY_CONFIG_ARRAY.lastName.step.length > 0)
                                            ?
                                            ((DYNAMIC_DISPLAY_CONFIG_ARRAY.lastName.step.includes(STEP_INFO.USER_DATA))
                                                    ? (this.dynamicFieldsRender({field: "lastName", errors, touched}))
                                                    : null
                                            ) : (this.dynamicFieldsRender({field: "lastName", errors, touched}))
                                        }

                                        {/* NATIONAL ID */}
                                        {(DYNAMIC_DISPLAY_CONFIG_ARRAY.codiceFiscale.step.length > 0)
                                            ?
                                            ((DYNAMIC_DISPLAY_CONFIG_ARRAY.codiceFiscale.step.includes(STEP_INFO.USER_DATA))
                                                    ? (this.dynamicFieldsRender({
                                                        field: "codiceFiscale",
                                                        errors,
                                                        touched
                                                    }))
                                                    : null
                                            )
                                            : ((!this.state.isEditMode && this.props.nationalid_show) || (this.state.isEditMode && this.props.nationalid_edit_show))
                                                ? this.dynamicFieldsRender({
                                                    field: "codiceFiscale",
                                                    errors,
                                                    touched
                                                })
                                                : null
                                        }


                                        {/* EMAIL */}
                                        {(DYNAMIC_DISPLAY_CONFIG_ARRAY.email.step.length > 0)
                                            ?
                                            ((DYNAMIC_DISPLAY_CONFIG_ARRAY.email.step.includes(STEP_INFO.USER_DATA))
                                                    ? (this.dynamicFieldsRender({field: "email", errors, touched}))
                                                    : null
                                            ) : (this.dynamicFieldsRender({field: "email", errors, touched}))
                                        }

                                        {/* EMAIL REPEAT */}
                                        {(DYNAMIC_DISPLAY_CONFIG_ARRAY.emailrepeat.step.length>0)
                                            ?
                                            ((DYNAMIC_DISPLAY_CONFIG_ARRAY.emailrepeat.step.includes(STEP_INFO.USER_DATA))
                                                    ? (this.dynamicFieldsRender({field: "emailrepeat", errors, touched}))
                                                    : null
                                            ) :((this.props.domainInfo?.emailrepeat_show)
                                                    ? (this.dynamicFieldsRender({field: "emailrepeat", errors, touched}))
                                                    : null
                                            )
                                        }

                                        {/* SPECIALIZZAZIONE */}
                                        {(DYNAMIC_DISPLAY_CONFIG_ARRAY.specializzazione.step.length > 0)
                                            ?
                                            ((DYNAMIC_DISPLAY_CONFIG_ARRAY.specializzazione.step.includes(STEP_INFO.USER_DATA))
                                                    ? this.dynamicFieldsRender({
                                                        field: "specializzazione",
                                                        errors,
                                                        touched,
                                                        additionalProps: {specLabel}
                                                    })
                                                    : null
                                            ) : null
                                        }

                                        {/* ANNO DI LAUREA */}
                                        {(DYNAMIC_DISPLAY_CONFIG_ARRAY.annoLaurea.step.length>0)
                                            ?
                                            ((DYNAMIC_DISPLAY_CONFIG_ARRAY.annoLaurea.step.includes(STEP_INFO.USER_DATA))
                                                    ? (this.dynamicFieldsRender({field: "annoLaurea", errors, touched}))
                                                    : null
                                            ) : null
                                        }

                                        {/* LUOGO DI LAVORO */}
                                        {(DYNAMIC_DISPLAY_CONFIG_ARRAY.luogoLavoro.step.length>0)
                                            ?
                                            ((DYNAMIC_DISPLAY_CONFIG_ARRAY.luogoLavoro.step.includes(STEP_INFO.USER_DATA))
                                                    ? (this.dynamicFieldsRender({field: "luogoLavoro", errors, touched}))
                                                    : null
                                            ) : null
                                        }

                                        {/* REPARTO */}
                                        {(DYNAMIC_DISPLAY_CONFIG_ARRAY.reparto.step.length > 0)
                                            ?
                                            ((DYNAMIC_DISPLAY_CONFIG_ARRAY.reparto.step.includes(STEP_INFO.USER_DATA))
                                                    ? this.dynamicFieldsRender({field: "reparto", errors, touched})
                                                    : null
                                            ) : null
                                        }

                                        {/* PHARMACY NAME */}
                                        {(DYNAMIC_DISPLAY_CONFIG_ARRAY.pharmacyName.step.length > 0)
                                            ?
                                            ((DYNAMIC_DISPLAY_CONFIG_ARRAY.pharmacyName.step.includes(STEP_INFO.USER_DATA))
                                                    ? this.dynamicFieldsRender({field: "pharmacyName", errors, touched})
                                                    : null
                                            ) : null
                                        }

                                        {/* INDIRIZZO */}
                                        {(DYNAMIC_DISPLAY_CONFIG_ARRAY.indirizzo.step.length>0)
                                            ?
                                            ((DYNAMIC_DISPLAY_CONFIG_ARRAY.indirizzo.step.includes(STEP_INFO.USER_DATA))
                                                    ? (this.dynamicFieldsRender({field: "indirizzo", errors, touched}))
                                                    : null
                                            ) : null
                                        }

                                        {/* CITTA */}
                                        {(DYNAMIC_DISPLAY_CONFIG_ARRAY.citta.step.length>0)
                                            ?
                                            ((DYNAMIC_DISPLAY_CONFIG_ARRAY.citta.step.includes(STEP_INFO.USER_DATA))
                                                    ? (this.dynamicFieldsRender({field: "citta", errors, touched}))
                                                    : null
                                            ) : null
                                        }

                                        {/* PROVINCIA */}
                                        {/* the control for the step is on the city because provinces has not declared into CONFIG_ARRAY*/}
                                        {(DYNAMIC_DISPLAY_CONFIG_ARRAY.provinces.step.length > 0)
                                            ?
                                            ((DYNAMIC_DISPLAY_CONFIG_ARRAY.provinces.step.includes(STEP_INFO.USER_DATA))
                                                    ? this.dynamicFieldsRender({
                                                        field: "provincia",
                                                        errors,
                                                        touched,
                                                        additionalProps: {provinces}
                                                    })
                                                    : null
                                            ) : null
                                        }

                                        {/* CAP */}
                                        {(DYNAMIC_DISPLAY_CONFIG_ARRAY.cap.step.length>0)
                                            ?
                                            ((DYNAMIC_DISPLAY_CONFIG_ARRAY.cap.step.includes(STEP_INFO.USER_DATA))
                                                    ? (this.dynamicFieldsRender({field: "cap", errors, touched}))
                                                    : null
                                            ) : null
                                        }

                                        {/* PHONE */}
                                        {(DYNAMIC_DISPLAY_CONFIG_ARRAY.phone.step.length > 0)
                                            ?
                                            ((DYNAMIC_DISPLAY_CONFIG_ARRAY.phone.step.includes(STEP_INFO.USER_DATA))
                                                    ? this.dynamicFieldsRender({field: "phone", errors, touched})
                                                    : null
                                            ) : null
                                        }

                                        {/* CONSENTS */}
                                        {(DYNAMIC_DISPLAY_CONFIG_ARRAY.consents.step.length > 0)
                                            ?
                                            ((DYNAMIC_DISPLAY_CONFIG_ARRAY.consents.step.includes(STEP_INFO.USER_DATA))
                                                    ?
                                                    ((this.state.consentsFetched.hasOwnProperty("c0") && this.state.consentsFetched["c0"].manualInsert)
                                                            ? this.dynamicFieldsRender({
                                                                field: "consents",
                                                                errors,
                                                                touched
                                                            })
                                                            : null
                                                    ) : null
                                            ) : ((this.state.consentsFetched.hasOwnProperty("c0") && this.state.consentsFetched["c0"].manualInsert)
                                                    ? this.dynamicFieldsRender({
                                                        field: "consents",
                                                        errors,
                                                        touched
                                                    })
                                                    : null
                                            )
                                        }


                                        {!i18n.t('professional.privacyPolicyNotice_' + this.props.profType).includes("professional.privacyPolicyNotice_") ?
                                            <div className="o-prose">
                                                <div className="e-content">
                                                    <p className="f-1"
                                                       dangerouslySetInnerHTML={{__html: i18n.t('professional.privacyPolicyNotice_' + this.props.profType)}}></p>
                                                </div>
                                            </div>
                                            : ""
                                        }

                                        {this.consentsToRenderBuilder(errors, touched)}
                                    </>)}

                                    {this.state.step === STEP_INFO.DEEP_SEARCH && (<>
                                        <Field name="step" type="hidden"/>
                                        <div className="o-prose">
                                            <div className="e-content">
                                                <p className="f-1" dangerouslySetInnerHTML={{
                                                    __html:
                                                        !i18n.t('professional.intro_step2_' + this.props.profType).includes("professional.intro_step2_") ?
                                                            i18n.t('professional.intro_step2_' + this.props.profType) :
                                                            i18n.t('professional.intro_step2')
                                                }}/>
                                                {/* <p className="f-1 text-right">
                                                    *Informazioni obbligatorie
                                                </p> */}
                                            </div>
                                        </div>
                                        {/* NOTE: non previsto dalla grafica nuova
                                            <h4 className="card-subtitle mb-4 mt-4">{i18n.t('professional.titleProfInfo')}</h4> */}

                                        <div className="validation-summary-valid">
                                            <ul>
                                                <li style={{display: "none"}}></li>
                                            </ul>
                                        </div>
                                        {/* /.validation-summary-valid */}

                                        {/* NAME */}
                                        {(DYNAMIC_DISPLAY_CONFIG_ARRAY.firstName.step.length > 0)
                                            ?
                                            ((DYNAMIC_DISPLAY_CONFIG_ARRAY.firstName.step.includes(STEP_INFO.DEEP_SEARCH))
                                                    ? (this.dynamicFieldsRender({field: "firstName", errors, touched}))
                                                    : null
                                            )
                                            : null
                                        }

                                        {/* SURNAME */}
                                        {(DYNAMIC_DISPLAY_CONFIG_ARRAY.lastName.step.length > 0)
                                            ?
                                            ((DYNAMIC_DISPLAY_CONFIG_ARRAY.lastName.step.includes(STEP_INFO.DEEP_SEARCH))
                                                    ? (this.dynamicFieldsRender({field: "lastName", errors, touched}))
                                                    : null
                                            ) : null
                                        }

                                        {/* NATIONAL ID */}
                                        {(DYNAMIC_DISPLAY_CONFIG_ARRAY.codiceFiscale.step.length > 0)
                                            ?
                                            ((DYNAMIC_DISPLAY_CONFIG_ARRAY.codiceFiscale.step.includes(STEP_INFO.DEEP_SEARCH))
                                                    ? (this.dynamicFieldsRender({
                                                        field: "codiceFiscale",
                                                        errors,
                                                        touched
                                                    }))
                                                    : null
                                            )
                                            : null
                                        }

                                        {/* EMAIL */}
                                        {(DYNAMIC_DISPLAY_CONFIG_ARRAY.email.step.length > 0)
                                            ?
                                            ((DYNAMIC_DISPLAY_CONFIG_ARRAY.email.step.includes(STEP_INFO.DEEP_SEARCH))
                                                    ? (this.dynamicFieldsRender({field: "email", errors, touched}))
                                                    : null
                                            ) : null
                                        }

                                        {/* EMAIL REPEAT */}
                                        {(DYNAMIC_DISPLAY_CONFIG_ARRAY.emailrepeat.step.length>0)
                                            ?
                                            ((DYNAMIC_DISPLAY_CONFIG_ARRAY.emailrepeat.step.includes(STEP_INFO.DEEP_SEARCH))
                                                    ? (this.dynamicFieldsRender({field: "emailrepeat", errors, touched}))
                                                    : null
                                            ) : null
                                        }

                                        {/* SPECIALIZZAZIONE */}
                                        {(DYNAMIC_DISPLAY_CONFIG_ARRAY.specializzazione.step.length > 0)
                                            ? ((DYNAMIC_DISPLAY_CONFIG_ARRAY.specializzazione.step.includes(STEP_INFO.DEEP_SEARCH))
                                                    ? this.dynamicFieldsRender({
                                                        field: "specializzazione",
                                                        errors,
                                                        touched,
                                                        additionalProps: {specLabel}
                                                    })
                                                    : null
                                            ) : this.dynamicFieldsRender({
                                                field: "specializzazione",
                                                errors,
                                                touched,
                                                additionalProps: {specLabel}
                                            })
                                        }

                                        {/* ANNO DI LAUREA */}
                                        {(DYNAMIC_DISPLAY_CONFIG_ARRAY.annoLaurea.step.length > 0)
                                            ? ((DYNAMIC_DISPLAY_CONFIG_ARRAY.annoLaurea.step.includes(STEP_INFO.DEEP_SEARCH))
                                                    ? this.dynamicFieldsRender({field: "annoLaurea", errors, touched})
                                                    : null
                                            ) : this.dynamicFieldsRender({field: "annoLaurea", errors, touched})
                                        }

                                        {/* LUOGO DI LAVORO */}
                                        {(DYNAMIC_DISPLAY_CONFIG_ARRAY.luogoLavoro.step.length > 0)
                                            ? ((DYNAMIC_DISPLAY_CONFIG_ARRAY.luogoLavoro.step.includes(STEP_INFO.DEEP_SEARCH))
                                                    ? this.dynamicFieldsRender({field: "luogoLavoro", errors, touched})
                                                    : null
                                            )  :((this.props.domainInfo?.luogoLavoro_show)
                                                    ? (this.dynamicFieldsRender({field: "luogoLavoro", errors, touched}))
                                                    : null
                                            )
                                        }

                                        {/* REPARTO */}
                                        {(DYNAMIC_DISPLAY_CONFIG_ARRAY.reparto.step.length > 0)
                                            ? ((DYNAMIC_DISPLAY_CONFIG_ARRAY.reparto.step.includes(STEP_INFO.DEEP_SEARCH))
                                                    ? this.dynamicFieldsRender({field: "reparto", errors, touched})
                                                    : null
                                            ) :((this.props.domainInfo?.reparto_show)
                                                    ? (this.dynamicFieldsRender({field: "reparto", errors, touched}))
                                                    : null
                                            )
                                        }

                                        {/* PHARMACY NAME */}
                                        {/* This field was added in 9/12/24 */}
                                        {(DYNAMIC_DISPLAY_CONFIG_ARRAY.pharmacyName.step.length > 0)
                                            ?
                                            ((DYNAMIC_DISPLAY_CONFIG_ARRAY.pharmacyName.step.includes(STEP_INFO.DEEP_SEARCH))
                                                    ? this.dynamicFieldsRender({field: "pharmacyName", errors, touched})
                                                    : null
                                            )  :((this.props.domainInfo?.pharmacyName_show)
                                                    ? (this.dynamicFieldsRender({field: "pharmacyName", errors, touched}))
                                                    : null
                                            )
                                        }

                                        {/* INDIRIZZO */}
                                        {(DYNAMIC_DISPLAY_CONFIG_ARRAY.indirizzo.step.length > 0)
                                            ? ((DYNAMIC_DISPLAY_CONFIG_ARRAY.indirizzo.step.includes(STEP_INFO.DEEP_SEARCH))
                                                    ? this.dynamicFieldsRender({field: "indirizzo", errors, touched})
                                                    : null
                                            ) : this.dynamicFieldsRender({field: "indirizzo", errors, touched})
                                        }

                                        {/* CITTA */}
                                        {(DYNAMIC_DISPLAY_CONFIG_ARRAY.citta.step.length > 0)
                                            ? ((DYNAMIC_DISPLAY_CONFIG_ARRAY.citta.step.includes(STEP_INFO.DEEP_SEARCH))
                                                    ? this.dynamicFieldsRender({field: "citta", errors, touched})
                                                    : null
                                            ) : this.dynamicFieldsRender({field: "citta", errors, touched})
                                        }

                                        {/* PROVINCIA */}
                                        {/* the control for the step is on the city because provinces has not declared into CONFIG_ARRAY*/}
                                        {(DYNAMIC_DISPLAY_CONFIG_ARRAY.provinces.step.length > 0)
                                            ? ((DYNAMIC_DISPLAY_CONFIG_ARRAY.provinces.step.includes(STEP_INFO.DEEP_SEARCH))
                                                    ? this.dynamicFieldsRender({
                                                        field: "provincia",
                                                        errors,
                                                        touched,
                                                        additionalProps: {provinces}
                                                    })
                                                    : null
                                            ) : this.dynamicFieldsRender({
                                                field: "provincia",
                                                errors,
                                                touched,
                                                additionalProps: {provinces}
                                            })
                                        }

                                        {/* CAP */}
                                        {(DYNAMIC_DISPLAY_CONFIG_ARRAY.cap.step.length > 0)
                                            ? ((DYNAMIC_DISPLAY_CONFIG_ARRAY.cap.step.includes(STEP_INFO.DEEP_SEARCH))
                                                    ? this.dynamicFieldsRender({field: "cap", errors, touched})
                                                    : null
                                            ) : this.dynamicFieldsRender({field: "cap", errors, touched})
                                        }

                                        {/* PHONE */}
                                        {(DYNAMIC_DISPLAY_CONFIG_ARRAY.phone.step.length > 0)
                                            ? ((DYNAMIC_DISPLAY_CONFIG_ARRAY.phone.step.includes(STEP_INFO.DEEP_SEARCH))
                                                    ? this.dynamicFieldsRender({field: "phone", errors, touched})
                                                    : null
                                            ) : ((this.props.domainInfo?.phone_show)
                                                    ? this.dynamicFieldsRender({field: "phone", errors, touched})
                                                    : null
                                            )
                                        }

                                        {/* CONSENTS */}
                                        {(DYNAMIC_DISPLAY_CONFIG_ARRAY.consents.step.length > 0)
                                            ?
                                            ((DYNAMIC_DISPLAY_CONFIG_ARRAY.consents.step.includes(STEP_INFO.DEEP_SEARCH))
                                                    ?
                                                    ((this.state.consentsFetched.hasOwnProperty("c0") && this.state.consentsFetched["c0"].manualInsert)
                                                            ? this.dynamicFieldsRender({
                                                                field: "consents",
                                                                errors,
                                                                touched
                                                            })
                                                            : null
                                                    ) : null
                                            ) : null
                                        }

                                        {this.consentsToRenderBuilder(errors, touched)}
                                    </>)}

                                    {this.state.step === STEP_INFO.INTL_FLOW && (<>
                                        {/* WARNING: INTL FLOW HAS BEEN DISABLED HAS REQUIRED BY CANZONETTA, AID-449 */}
                                        <div className="form-row">
                                            <div className="form-group form-group-opts">
                                                <ol className="list list-opt">
                                                    <li className="form-check">
                                                        <Field type="checkbox" name="terms_intl" id="terms_intl"
                                                               className={'form-check-input c-checkbox' + (errors.terms_intl && touched.terms_intl ? ' is-invalid' : '')} />
                                                        <label htmlFor="terms_intl" className="c-label">
                                                            I declare to accept the <a href="https://www.angelinipharma.com/gdpr/terms/en" target="_blank" rel="noopener noreferrer">Terms of use</a> of the service and to have read and understood the <a href="https://www.angelinipharma.com/gdpr/en/" target="_blank" rel="noopener noreferrer">HCP privacy policy</a>*
                                                        </label>
                                                        <ErrorMessage name="terms_intl" component="div" className="invalid-feedback" />
                                                    </li>
                                                </ol>
                                            </div>
                                        </div>
                                    </>)}

                                    {this.state.step === STEP_INFO.CONSENT_PROMPT && (<>
                                        <div className="o-prose">
                                            <div className="e-content">
                                                <p className="f-1"
                                                   dangerouslySetInnerHTML={{__html: i18n.t('professional.preferencePrivacyText_' + this.props.profType)}} />
                                            </div>
                                        </div>
                                        <div className="validation-summary-valid">
                                            <ul>
                                                <li style={{display:"none"}}></li>
                                            </ul>
                                        </div>{/* /.validation-summary-valid */}

                                        { this.consentsToRenderBuilder(errors, touched) }
                                    </>)}

                                    {
                                        !i18n.t('professional.privacyUKText').includes("professional.privacyUKText")
                                        && (
                                            (this.state.step === STEP_INFO.USER_DATA && this.areThereConsentsInThisStep(STEP_INFO.USER_DATA))
                                            || (this.state.step === STEP_INFO.CONSENT_PROMPT && this.areThereConsentsInThisStep(STEP_INFO.CONSENT_PROMPT))
                                        ) ?
                                            <div className="o-prose">
                                                <div className="e-content">
                                                    <p className="f-1" dangerouslySetInnerHTML={{ __html: i18n.t('professional.privacyUKText') }} />
                                                </div>
                                            </div>
                                            : ""
                                    }

                                    <div className="form-row">
                                        <div className="form-group form-group-buttons">
                                            <div className="o-button o-button-full">
                                                <Field type="button" name="submit_btn" id="submit_btn" className="c-button c-button-primary txt-uppercase"
                                                       value={
                                                           !i18n.t('professional.submitBtn_' + this.props.profType).includes("professional.submitBtn_") ?
                                                               i18n.t('professional.submitBtn_' + this.props.profType) :
                                                               i18n.t('professional.submitBtn')
                                                       }
                                                       onClick={() => {
                                                           if(sessionStorage.getItem('aidReferrer')) sessionStorage.removeItem("aidReferrer");
                                                           if(!(isSubmitting || this.state.isSubmitClicked || this.state.isFetchProcessing)) {
                                                               this.setState({isSubmitClicked: true});
                                                               try {
                                                                   this.submitBtnManager(handleSubmit);
                                                               } finally {
                                                                   this.setState({isSubmitClicked: false});
                                                               }
                                                           }
                                                       }}
                                                       style={(isSubmitting || this.state.isSubmitClicked ||  this.state.isFetchProcessing)?
                                                           {
                                                               backgroundImage: `url(${OBJECTS_PATH.PICTURES.LOADING_GIF})`,
                                                               backgroundSize: "contain",
                                                               backgroundRepeat: "no-repeat",
                                                               backgroundPosition: "center",
                                                               color: "rgba(0,0,0,0)",
                                                           }
                                                           : {}
                                                       }
                                                       disabled={(isSubmitting || this.state.isSubmitClicked ||  this.state.isFetchProcessing)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>{/* /.u-wrap-fields */}
                            </fieldset>{/* /.c-fieldset */}
                            <ErrorFocus/>
                        </Form>

                        <Modal
                            isOpen={this.state.isModalOpen}
                            aria={{ labelledby: "heading", describedby: "full_description" }}
                            shouldCloseOnEsc={false}
                            shouldCloseOnOverlayClick={false}
                            className="aid-dialog"
                            overlayClassName="aid-dialog-overlay"
                        >
                            <div className="aid-dialog-container">
                                <div className="aid-dialog-close">
                                    <button className="button-close">
                                        {/* LOCALIZE: */}
                                        <span className="vh">Close</span>
                                        <span className="close-icon" onClick={()=> {
                                            this.closeModal(false, handleSubmit)
                                        }}></span>
                                    </button>
                                </div>

                                <div className="aid-dialog-content">
                                    <div className="aid-dialog-block">
                                        <div className="msg">
                                            <div className="msg-header txt-center">
                                                <h2 className="msg-title">{i18n.t('consentWarningTitle')}</h2>
                                            </div>
                                            <div className="msg-content">
                                                <p>{i18n.t('consentWarningBody')}</p>
                                            </div>
                                            <div className="msg-button-holder">
                                                <button className="c-button c-button-primary" onClick={() => {
                                                    this.closeModal(true, handleSubmit)
                                                }}>{i18n.t('consentWarningOk')}</button>
                                                <button className="c-button c-button-outline" onClick={() => {
                                                    this.closeModal(false, handleSubmit)
                                                }}>{i18n.t('consentWarningBack')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        <Modal
                            isOpen={this.state.isErrorModalOpen}
                            aria={{ labelledby: "heading",describedby: "full_description" }}
                            shouldCloseOnEsc={false}
                            shouldCloseOnOverlayClick={false}
                            className="aid-dialog"
                            overlayClassName="aid-dialog-overlay"
                        >
                            <div className="aid-dialog-container">
                                <div className="aid-dialog-close">
                                    <button className="button-close">
                                        {/* LOCALIZE: */}
                                        <span className="vh">Close</span>
                                        <span className="close-icon" onClick={()=> {
                                            this.setState({isErrorModalOpen: false, errorText: null});
                                            document.getElementsByTagName("html")[0].classList.remove("is-aid-dialog");
                                        }}></span>
                                    </button>
                                </div>

                                <div className="aid-dialog-content">
                                    <div className="aid-dialog-block">
                                        <div className="msg">
                                            <div className="msg-header txt-center">
                                                <h2 className="msg-title">{
                                                    !i18n.t('errorModal.titleHeader').includes('errorModal.titleHeader') ?
                                                        i18n.t('errorModal.titleHeader') :
                                                        "ERROR"
                                                }</h2>
                                            </div>
                                            <div className="msg-content">
                                                <p>{this.errorText}</p>
                                            </div>
                                            <div className="msg-button-holder">
                                                <button className="c-button c-button-primary" onClick={() => {
                                                    this.setState({ isErrorModalOpen: false, errorText: null });
                                                    document.getElementsByTagName("html")[0].classList.remove("is-aid-dialog");
                                                }}>OK</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </>)}
                </Formik>
            )
        }
    }
}
